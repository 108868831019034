
function storeInLocalStorage<T>(key: string, object: T) {


    const stringifiedObject = JSON.stringify(object);
    localStorage.setItem(key, stringifiedObject);
}

function loadFromLocalStorage<T>(key: string, defaultObject?: Partial<T>) {
    const stringifiedObject = localStorage.getItem(key);


    try {
        if (!stringifiedObject) throw new Error("Key was not found")
        const object = JSON.parse(stringifiedObject);
        return object as T;
    } catch (error) {

    }

    return defaultObject as T;
}



export { storeInLocalStorage, loadFromLocalStorage }