import { FC, useEffect } from "react";
import styles from "./TicketCardList.module.scss";
import globalStyles from "../../styles/Global.module.scss";

import classNames from "classnames";
import { Ticket } from "../../interfaces/Ticket.interface";
import TicketCardComponent from "../TicketCardComponent/TicketCardComponent";
import { Alert, Button, Stack, Typography } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Box } from "@mui/system";
import { useState } from "react";
import { SelectedTicket } from "../../interfaces/SelectedTicket";
import { useContext } from "react";
import { AppContext } from "../../AppContext";
import { TicketCategory } from "../../StrapiClient";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

interface TicketCardListProps {
  tickets: Ticket[];
  ticketCategories: TicketCategory[];
  onBuyClick: (tickets: Ticket[]) => void;
  onTicketsSelected?: (tickets: Ticket[]) => void;
}

const TicketCardList: FC<TicketCardListProps> = (props) => {
  const [selectedTickets, setSelectedTickets] = useState<SelectedTicket[]>([]);

  useEffect(() => {
    if (props.onTicketsSelected) {
      props.onTicketsSelected(selectedTickets);
    }
  }, [selectedTickets, props]);

  const { ticketNotice } = useContext(AppContext);

  function getTicketsForCategory(category: TicketCategory) {
    return props.tickets.filter((ticket) => ticket.category === category.id);
  }

  function getTicketsWithoutCategory() {
    return props.tickets.filter((ticket) => ticket.category === undefined);
  }

  return (
    <Stack sx={{ padding: 2 }} gap={2}>
      {props.ticketCategories
        .filter((cat) => getTicketsForCategory(cat).length > 0)
        .map((category) => (
          <Stack
            className={classNames(globalStyles["flex-column"], styles.list)}
            gap={2}
          >
            <Typography variant="h5" sx={{ color: "black", marginTop: "32px" }}>
              {category.name}
            </Typography>
            {getTicketsForCategory(category).map((ticket) => (
              <TicketCardComponent
                onTicketSelected={(discounted, ticket) => {
                  const selectedTicket = {
                    ...ticket,
                    discounted,
                  } as SelectedTicket;
                  setSelectedTickets([...selectedTickets, selectedTicket]);
                }}
                onTicketRemoved={(discounted, ticket) => {
                  const removedTicket = selectedTickets.findIndex(
                    (_ticket) =>
                      (ticket.id === _ticket.id &&
                        _ticket.discounted === discounted) ||
                      ticket.id === _ticket.id
                  );

                  if (removedTicket > -1) {
                    const newTickets = [...selectedTickets];
                    newTickets.splice(removedTicket, 1);
                    setSelectedTickets(newTickets);
                  }
                }}
                key={ticket.id}
                ticket={ticket}
              />
            ))}
          </Stack>
        ))}

      {getTicketsWithoutCategory().length > 0 && (
        <Stack
          className={classNames(globalStyles["flex-column"], styles.list)}
          gap={2}
        >
          <Typography variant="h5" sx={{ color: "black" }}>
            Ohne Kategorie
          </Typography>
          {getTicketsWithoutCategory().map((ticket) => (
            <TicketCardComponent
              onTicketSelected={(discounted, ticket) => {
                const selectedTicket = {
                  ...ticket,
                  discounted,
                } as SelectedTicket;
                setSelectedTickets([...selectedTickets, selectedTicket]);
              }}
              onTicketRemoved={(discounted, ticket) => {
                const removedTicket = selectedTickets.findIndex(
                  (_ticket) =>
                    (ticket.id === _ticket.id &&
                      _ticket.discounted === discounted) ||
                    ticket.id === _ticket.id
                );

                if (removedTicket > -1) {
                  const newTickets = [...selectedTickets];
                  newTickets.splice(removedTicket, 1);
                  setSelectedTickets(newTickets);
                }
              }}
              key={ticket.id}
              ticket={ticket}
            />
          ))}
        </Stack>
      )}

      <Box
        sx={{ display: "flex", justifyContent: "flex-end" }}
        className={classNames({
          [styles.checkout]: selectedTickets.length > 0,
        })}
      >
        <Button
          onClick={() => props.onBuyClick(selectedTickets)}
          startIcon={<ShoppingCartIcon />}
          variant="contained"
          disabled={selectedTickets.length <= 0}
        >
          {`Jetzt für ${selectedTickets
            .reduce((prev, curr) => prev + curr.price, 0)
            .toLocaleString("de-DE", {
              style: "currency",
              currency: "EUR",
            })} bestellen`}
        </Button>
      </Box>

      <Alert security="success">
        <Stack gap={2}>
          <div>
            Es gelten die{" "}
            <a
              href="https://2takt-festival.de/?page_id=44"
              target="_blank"
              rel="noreferrer"
              style={{color: "black"}}
            >
              AGB
            </a>{" "}
            und die{" "}
            <a
              href="https://2takt-festival.de/?page_id=46"
              target="_blank"
              rel="noreferrer"
              style={{color: "black"}}
            >
              Datenschutzerklärung
            </a>
            .
          </div>
          {ticketNotice?.noticeText && (
            <ReactMarkdown
              children={ticketNotice.noticeText}
              remarkPlugins={[remarkGfm]}
            />
          )}
        </Stack>
      </Alert>
    </Stack>
  );
};

export default TicketCardList;
