import { TempUser } from "./StrapiClient";

function uuidv4() {

    // @ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}



function getPixelIfExists() {
    // @ts-ignore
    if (typeof window !== "undefined" && typeof window.fbq !== "undefined") {
        // @ts-ignore
        return window.fbq
    }
}

const tries: { [id: string]: number } = {};
const maxTries = 10;
let trackerTimeout: NodeJS.Timeout | null = null;

const randomUuid = uuidv4();

function track(key: string, body?: any) {

    setTimeout(() => {



        const eventID = `${key}_${btoa(JSON.stringify(body ?? {}))}_${(JSON.parse(localStorage.getItem("2Takt_User") ?? '{ "id": "' + randomUuid + '" }') as TempUser).id}`

        if ((tries?.[eventID] ?? 0) >= maxTries) return

        tries[eventID] = (tries?.[eventID] ?? 0) + 1;

        const pixelFn = getPixelIfExists();
        if (pixelFn) {
            pixelFn("track", key, body, { eventID });
        } else {

            console.log("Could not find", `fbq("track", ${key}${body ? ", " + JSON.stringify(body) : ""}, ${JSON.stringify({ eventID })})`);

            if (trackerTimeout) clearTimeout(trackerTimeout);
            trackerTimeout = setTimeout(() => {
                const pixelFn = getPixelIfExists();
                if (pixelFn) {
                    pixelFn("track", key, body, { eventID });
                } else {
                    track(key, body);
                }
            }, 500)
        }

    }, 1500)
}

const pixel = {
    track
}
export default pixel;