import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { FC } from "react";
import { AppContext } from "../../AppContext";
import { Order } from "../../interfaces/Order";
import StrapiClient from "../../StrapiClient";
import LabelAndSublabel from "../LabelAndSublabel";
import PaymentMethodLabel from "../PaymentMethodLabel";
import * as queryString from "query-string";
import PaymentStautsLabel from "../PaymentStautsLabel";
import { PaymentStatus } from "../../interfaces/PaymentStatus";
import { PaymentMethod } from "../../interfaces/PaymentMethod";
import pixel from "../../facebookPixel";

interface CheckoutDoneProps {}

let refreshCounter = 0;

const CheckoutDone: FC<CheckoutDoneProps> = () => {
  const params = queryString.parse(window.location.search);

  const { user, orderId } = useContext(AppContext);
  const [order, setOrder] = useState<null | Order>(null);

  function reload() {
    if (orderId && user && refreshCounter === 0) {
      refreshCounter += 1;
      setTimeout(() => (refreshCounter = 0), 4000);
      StrapiClient.getOrder(
        orderId,
        // @ts-ignore
        params?.accessToken || user?.accessToken
      ).then((order) => {
        setOrder(order);
      });
    }
  }

  useEffect(() => {
    reload();
    setTimeout(reload, 1000 * 5);
    setTimeout(reload, 1000 * 60);
  }, []);

  useEffect(() => {
    if (order) {
      pixel.track("Purchase", {
        value: order.totalPrice,
        currency: "EUR",
      });
    }
  }, [order]);

  if (order)
    if (order.error)
      return (
        <Stack spacing={2} padding={2}>
          <Card elevation={2} sx={{ padding: 2 }}>
            <Stack gap={2} alignItems="center" justifyContent="center">
              <Typography variant="h4">Da lief was schief</Typography>
              <Alert severity="error">{order.error}</Alert>
            </Stack>
          </Card>
          <Card elevation={2} sx={{ padding: 2 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="body1">Noch nicht genug?</Typography>
              <Button
                variant="outlined"
                onClick={() => {
                  //@ts-ignore
                  window.location = "https://2takt-festival.de/?page_id=7";
                }}
              >
                Weitere Tickets bestellen
              </Button>
            </Stack>
          </Card>
        </Stack>
      );
    else
      return (
        <Stack spacing={2} padding={2}>
          {[
            PaymentStatus.Canceled,
            PaymentStatus.Expired,
            PaymentStatus.Failed,
          ].includes(order.status) && (
            <Alert severity="error">
              Deine Bezahlung war nicht erfolgreich. Bitte versuche es erneut
              und sollte das Problem bestehen melde dich unter
              kontakt@2tak-festival.de.&nbsp;
              <Button
                color="error"
                variant="outlined"
                size="small"
                onClick={() => {
                  StrapiClient.retryOrder(
                    order.id,
                    // @ts-ignore
                    params?.accessToken || user?.accessToken
                    // @ts-ignore
                  ).then((result) => (window.location = result.checkoutUrl));
                }}
              >
                Nochmal versuchen
              </Button>
            </Alert>
          )}

          <Card elevation={2} sx={{ padding: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Typography variant="h6">Deine Ticketbestellung</Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography variant="body1">
                  Sobald du Deine Bestellung bezahlst, erhälst du deine Tickets
                  per Email oder kannst Sie hier herunterladen.
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <LabelAndSublabel label="Bestellnummer">
                  {"#" + order.id}
                </LabelAndSublabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <LabelAndSublabel label="Gesantpreis">
                  {order.totalPrice.toLocaleString("de-DE", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </LabelAndSublabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <LabelAndSublabel label="Enthaltene MwSt.">
                  {order.totalMwSt.toLocaleString("de-DE", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </LabelAndSublabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <PaymentMethodLabel paymentMethodId={order.paymentMethod} />
              </Grid>
              <Grid item xs={12} md={6}>
                <PaymentStautsLabel paymentStatus={order.status} />
              </Grid>
            </Grid>
          </Card>

          {order.wireTransferDetails &&
            order.paymentMethod === PaymentMethod.Überweisung &&
            order.status !== PaymentStatus.Paid && (
              <Card sx={{ border: "3px solid red" }}>
                <Stack gap={2} padding={2}>
                  <Typography variant="h5">
                    Bitte Überweise den Betrag an dieses Konto
                  </Typography>
                  <Typography color="red">
                    Du erhälst diese Informationen auch in einer separaten
                    Email. Bitte gib unbedingt den richtigen Verwendungszweck
                    an!
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <LabelAndSublabel label="Empfänger">
                        {order.wireTransferDetails.bankName}
                      </LabelAndSublabel>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LabelAndSublabel label="IBAN">
                        {order.wireTransferDetails.bankAccount}
                      </LabelAndSublabel>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LabelAndSublabel label="BIC">
                        {order.wireTransferDetails.bankBic}
                      </LabelAndSublabel>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LabelAndSublabel label="Betrag">
                        {order.totalPrice}
                      </LabelAndSublabel>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <LabelAndSublabel label="Verwendungszweck">
                        {order.wireTransferDetails.transferReference}
                      </LabelAndSublabel>
                    </Grid>
                  </Grid>
                </Stack>
              </Card>
            )}

          {order.tickets.map((ticket) => (
            <Card elevation={2} sx={{ padding: 2 }}>
              <Grid container gap={2}>
                <Grid item xs={12} md={6}>
                  <LabelAndSublabel label="Ticketname">
                    {ticket.displayName}
                  </LabelAndSublabel>
                </Grid>
                <Grid item xs={12} md={2}>
                  <LabelAndSublabel label="Gültig">
                    {ticket.paid ? "Ja" : "Nein"}
                  </LabelAndSublabel>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Tooltip
                    title={
                      ticket.paid
                        ? ""
                        : "Du kannst das Ticket herunterladen sobald die Bezahlung abgeschlossen ist."
                    }
                  >
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      sx={{ height: "100%" }}
                    >
                      <a
                        target="_blank"
                        href={ticket.paid ? ticket.pdf : undefined}
                        download={
                          ticket.displayName + "_" + ticket.ticketUUID + ".pdf"
                        }
                        rel="noreferrer"
                      >
                        <Button
                          disabled={!ticket.paid}
                          variant="contained"
                          disableElevation
                        >
                          Herunterladen
                        </Button>
                      </a>
                    </Stack>
                  </Tooltip>
                </Grid>
              </Grid>
            </Card>
          ))}

          {order?.promoCode && (
            <Card
              elevation={2}
              sx={{ padding: 2, border: "solid 4px #febc39" }}
            >
              <Stack justifyContent="space-between" alignItems="flex-start">
                <Typography variant="h5">
                  <b>🫱🏽‍🫲🏽 Lade deine Freunde ein</b>
                </Typography>
                <Typography variant="h5">
                  Schicke deinen Code: <b>{order?.promoCode}</b> an deine
                  Freunde und erhalte 2 Token jedes Mal, wenn Sie ein Ticket
                  bestellen*
                </Typography>
                <Typography variant="caption">
                  *2 Token pro bestelltem Ticket. Maximal 20. Mindestens 2
                  eingelöste Tickets. Alles weiter findest du im Abschnitt 12
                  der AGB.
                </Typography>
              </Stack>
            </Card>
          )}

          <Card elevation={2} sx={{ padding: 2 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="body1">Noch nicht genug?</Typography>
              <Button
                variant="outlined"
                onClick={() => {
                  //@ts-ignore
                  window.location = "https://2takt-festival.de/?page_id=7";
                }}
              >
                Weitere Tickets bestellen
              </Button>
            </Stack>
          </Card>

          <Alert severity="info">
            Was schief gelaufen bei deiner Bestellung? Schreib uns
            kontakt@2takt-festival.de
          </Alert>
        </Stack>
      );
  else
    return (
      <Card elevation={2} sx={{ padding: 2 }}>
        <Stack spacing={2} alignItems="center" justifyContent="center">
          <Typography variant="h6">Lade Bestellung...</Typography>
          <CircularProgress></CircularProgress>
        </Stack>
      </Card>
    );
};

export default CheckoutDone;
