import React from "react";
import logo from "./logo.svg";
import "./App.css";
import TicketCardList from "./components/TicketCardList/TicketCardList";
import { Ticket } from "./interfaces/Ticket.interface";
import { useState } from "react";
import { useEffect } from "react";
import CheckoutStepper from "./components/CheckoutStepper/CheckoutStepper";
import { Box } from "@mui/system";
import { JsxElement } from "typescript";
import { EditAttributes, PanoramaVerticalSelect } from "@mui/icons-material";
import { object } from "yup";
import StrapiClient, {
  TempUser,
  TicketCategory,
  TicketNotice,
} from "./StrapiClient";
import {
  loadFromLocalStorage,
  storeInLocalStorage,
} from "./helpers/LocalStorageHelper";
import { createContext } from "react";

import * as queryString from "query-string";
import { AppContext } from "./AppContext";
import { StrapiToObject } from "./helpers/StrapiNormalizeObjectHelper";
import CheckoutDone from "./components/CheckoutDone/CheckoutDone";
import { TicketOrder } from "./interfaces/TicketOrder";
import PDFTicket from "./components/PDFTicket/PDFTicket";
import { MolliePaymentMethod } from "./interfaces/MolliePaymentMethod";
import PhaseStepper from "./components/PhaseStepper";
import { Stack } from "@mui/material";
import pixel from "./facebookPixel";

function App() {
  const [user, setUser] = useState<TempUser | null>(null);
  const [ticketNotice, setTicketNotice] = useState<TicketNotice>();

  const [ticketOrder, setTicketOrder] = useState<TicketOrder | null>(null);
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<MolliePaymentMethod[]>(
    []
  );
  const [ticketCategories, setTicketCategories] = useState<TicketCategory[]>(
    []
  );
  const [selectedTickets, setSelectedTickets] = useState<Ticket[]>([]);
  const [bookingStep, setBookingStep] = useState(0);

  const [orderId, setOrderId] = useState<null | number>(null);

  useEffect(() => {
    if (bookingStep === 0) {
      pixel.track("ViewContent");
    } else if (bookingStep === 1) {
      pixel.track("InitiateCheckout");
    }
  }, [bookingStep]);

  useEffect(() => {
    if (!localStorage.getItem("clearLocalStorage")) {
      localStorage.clear();
      localStorage.setItem("clearLocalStorage", "CLEAR");
    }

    StrapiClient.getTicketNotice().then((result) => setTicketNotice(result));
    StrapiClient.getPaymentMethods().then((result) =>
      setPaymentMethods(result.reverse())
    );

    const loadedUser = loadFromLocalStorage<TempUser>("2Takt_User");
    if (loadedUser) {
      setUser(loadedUser);
    } else {
      StrapiClient.getTempUser().then((user) => {
        storeInLocalStorage("2Takt_User", user);
        setUser(user);
      });
    }

    const params = queryString.parse(window.location.search);

    if (params?.genTicket && params.ticket && params.accessToken) {
      //@ts-ignore
      StrapiClient.getTicket(parseInt(params.ticket), params.accessToken).then(
        (ticketOrder) => {
          setBookingStep(3);
          setTicketOrder(ticketOrder);
        }
      );
      return;
    }

    if (params.order && loadedUser) {
      //@ts-ignore
      setOrderId(parseInt(params.order));
      setBookingStep(2);
    }

    StrapiClient.getTickets().then((result) => {
      const availableTickets = result;
      setTickets(availableTickets);
    });
    StrapiClient.getTicketCategories().then((categories) => {
      setTicketCategories(categories);
    });
  }, []);

  let CurrentStep = <span></span>;

  switch (bookingStep) {
    case 0:
      CurrentStep = (
        <Stack>
          {/* <PhaseStepper /> */}
          <TicketCardList
            onTicketsSelected={(tickets) => {
              if (tickets.length > 0) {
                const ticketAmountMap = tickets.reduce(
                  (prev, curr) => ({
                    ...prev,
                    [curr.id]: (prev?.[curr.id] ?? 0) + 1,
                  }),
                  {} as { [key: number]: number }
                );

                const selectedTickets = tickets.map((item) => ({
                  name: item.displayName,
                  id: item.id,
                  price: item.price,
                  amount: ticketAmountMap?.[item.id] ?? 1,
                }));

                pixel.track("AddToCart", { selectedTickets });
              }
            }}
            onBuyClick={(tickets) => {
              setBookingStep(1);
              setSelectedTickets(tickets);
            }}
            tickets={tickets}
            ticketCategories={ticketCategories}
          ></TicketCardList>
        </Stack>
      );
      break;
    case 1:
      CurrentStep = (
        <CheckoutStepper tickets={selectedTickets}></CheckoutStepper>
      );
      break;

    case 2:
      CurrentStep = <CheckoutDone />;
      break;
    case 3:
      CurrentStep = <PDFTicket ticketOrder={ticketOrder} />;
  }

  return (
    <Box sx={{ maxWidth: 850 }}>
      <AppContext.Provider
        value={{ user, setUser, orderId, ticketNotice, paymentMethods }}
      >
        {CurrentStep}
      </AppContext.Provider>
    </Box>
  );
}

export default App;
