import { createTheme } from "@mui/material/styles";

export default createTheme({
  components: {},
  palette: {
    mode: "light",
    primary: {
      main: "#ffbd3a",
    },
    secondary: {
      main: "#26c6da",
    },
  },
});
