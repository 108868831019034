import { StrapiToObject } from "./helpers/StrapiNormalizeObjectHelper";
import { Address } from "./interfaces/Address";
import { MolliePaymentMethod } from "./interfaces/MolliePaymentMethod";
import { Order } from "./interfaces/Order";
import { Ticket } from "./interfaces/Ticket.interface";
import { TicketOrder } from "./interfaces/TicketOrder";

export interface TicketNotice {
    noticeText: string;
}
export interface TempUser {
    id: number;
    accessToken: string;
}

export interface TicketCategory {
    name: string;
    id: number
}

async function getTicketCategories() {
    return fetch(process.env.REACT_APP_API_HOST + "/ticket-categories?sort=order:asc").then((res) => res.json()).then((res: any) => res.data.map((object: any) => ({ ...object.attributes, id: object.id }))) as Promise<TicketCategory[]>
}

async function getTickets() {
    return fetch(process.env.REACT_APP_API_HOST + "/tickets?populate=*&sort=order:asc")
        .then((response) => response.json()).then((json) => json.data.map((object: any) => {
            return {
                ...object.attributes,
                id: object.id,
                category: object.attributes?.category?.data?.id
            }
        })) as Promise<Ticket[]>
}

async function getTempUser() {
    return fetch(process.env.REACT_APP_API_HOST + "/temp-users", { headers: { "Content-Type": "application/json" }, method: "POST", body: JSON.stringify({ data: {} }) }).then((response) => response.json()).then((user) => StrapiToObject<TempUser>(user)) as Promise<TempUser>
}

async function createOrder(ticketIds: number[], paymentMethod: string, address: Address, accessToken?: string, coupon?: string, testCouponCode?: string) {
    return fetch(process.env.REACT_APP_API_HOST + "/orders", { headers: { "Content-Type": "application/json" }, method: "POST", body: JSON.stringify({ data: { accessToken, ticketIds, address, paymentMethod, promoCodeUsed: coupon, testCouponCode } }) }).then((response) => response.json()) as Promise<{ checkoutUrl: string }>
}

async function getOrder(orderId: number, accessToken?: string) {
    return fetch(process.env.REACT_APP_API_HOST + "/orders/" + orderId + "/status?accessToken=" + accessToken, { headers: { "Content-Type": "application/json" }, method: "GET" }).then((response) => response.json()) as Promise<Order>
}

async function retryOrder(orderId: number, accessToken?: string) {
    return fetch(process.env.REACT_APP_API_HOST + "/orders/" + orderId + "/retry?accessToken=" + accessToken, { headers: { "Content-Type": "application/json" }, body: JSON.stringify({ data: {} }), method: "POST" }).then((response) => response.json()) as Promise<{ checkoutUrl: string }>
}

async function getTicket(ticketId: number, accessToken?: string) {
    return fetch(process.env.REACT_APP_API_HOST + "/orders/" + ticketId + "/print" + "?accessToken=" + accessToken, { headers: { "Content-Type": "application/json" }, method: "GET" }).then((response) => response.json()) as Promise<TicketOrder>
}

async function getPaymentMethods() {
    return fetch(process.env.REACT_APP_API_HOST + "/orders/methods", { headers: { "Content-Type": "application/json" }, method: "GET" }).then((response) => response.json()) as Promise<MolliePaymentMethod[]>
}

async function getTicketNotice() {
    return fetch(process.env.REACT_APP_API_HOST + "/ticket-notice", { headers: { "Content-Type": "application/json" }, method: "GET" }).then((response) => response.json()).then((json) => StrapiToObject<TicketNotice>(json)) as Promise<TicketNotice>
}
async function getCurrentPhase() {
    return fetch(process.env.REACT_APP_API_HOST + "/current-phase", { headers: { "Content-Type": "application/json" }, method: "GET" }).then((response) => response.json()).then((json) => StrapiToObject<{ currentPhase?: number }>(json)) as Promise<{ currentPhase?: number }>
}

const StrapiClient = { getCurrentPhase, getPaymentMethods, getTempUser, createOrder, getOrder, getTickets, getTicket, getTicketNotice, retryOrder, getTicketCategories };
export default StrapiClient;