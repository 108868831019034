import { Typography } from "@mui/material";
import { FC, useEffect } from "react";
import { TicketOrder } from "../../interfaces/TicketOrder";
import "./PDFTicket.scss";

import OverlayImage from "./assets/image/background.png";
import EllipsisImage from "./assets/image/ellipsis.png";
import LogoImage from "./assets/image/2takt-logo.png";

import Content1Image from "./assets/image/2takt-ticket-logo-1.png";
import Content2Image from "./assets/image/2takt-ticket-logo-2.png";
import Content3Image from "./assets/image/2takt-ticket-logo-3.png";
import { PropaneRounded } from "@mui/icons-material";
import pixel from "../../facebookPixel";

interface PDFTicketProps {
  ticketOrder: TicketOrder | null;
}

function padNumber(value: number) {
  return value < 10 ? "0" + value : value + "";
}

const PDFTicket: FC<PDFTicketProps> = (props) => {
  if (props.ticketOrder) {
    const backgroundColor = props.ticketOrder.color;

    return (
      <div className="zweitakt-ticket">
        <img className="overlay-image" src={OverlayImage} alt="" />
        <div className="overlay-image-bg" style={{ backgroundColor }} />
        <img className="ellipsis top" src={EllipsisImage} />
        <img className="ellipsis bottom" src={EllipsisImage} />
        <img className="logo" src={LogoImage} />
        {props.ticketOrder.type === 0 && (
          <img className="content-1" src={Content1Image} />
        )}{" "}
        {props.ticketOrder.type === 1 && (
          <img className="content-2" src={Content2Image} />
        )}{" "}
        {props.ticketOrder.type === 2 && (
          <img className="content-3" src={Content3Image} />
        )}
        {/* <img className="content-2" src={Content2Image} /> */}
        {/* <img className="content-3" src={Content3Image} /> */}
        <div className="border-container">
          <div style={{ borderColor: backgroundColor }} className="border" />
        </div>
        <h3 className="date">{props.ticketOrder.displayDate}</h3>
        <div className="ticket-name" style={{ color: backgroundColor }}>
          <h2> {props.ticketOrder.shortName}</h2>
          {props.ticketOrder.discounted && (
            <p className="discounted">(Ermäßigt)</p>
          )}
        </div>
        <div
          className="data"
          style={{ bottom: props.ticketOrder.discounted ? 87 : 106 }}
        >
          <div className="rows">
            <span>
              {"Buchungsdatum: " +
                new Date(props.ticketOrder.createdAt).toLocaleDateString()}
            </span>
            <span>{"Ticket ID: " + props.ticketOrder.id}</span>
            <span>
              {"Camping: " + (props.ticketOrder.camping ? "Ja" : "Nein")}
            </span>
            <span>
              {"Preis: " +
                props.ticketOrder.price.toLocaleString("de-DE", {
                  style: "currency",
                  currency: "EUR",
                })}
            </span>
            <span>{"Gebucht von: " + props.ticketOrder.bookedBy}</span>
          </div>
          <img className="qr-code" src={props.ticketOrder.qrCode} />
        </div>
        <div className="notice">{props.ticketOrder.noticeOnTicket}</div>
      </div>
    );
  } else
    return (
      <div className="zweitakt-ticket">
        <Typography variant="h6" sx={{ color: "white" }}>
          Da lief was schief. Bitte wende dich an kontakt@2takt-festival.de
        </Typography>
      </div>
    );
};

export default PDFTicket;

/**
 *
 * full: #998cc2
 * Full Weekend
 * fr: #a2daeb
 * Freitag
 * sa: #ee6f38
 * Samstag
 *
 * Camping Freitag
 * Camping Samstag
 *
 */
