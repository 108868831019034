import classNames from "classnames";
import { FC, useState } from "react";
import { Ticket } from "../../interfaces/Ticket.interface";
import styles from "./TicketCardComponent.module.scss";
import globalStyles from "../../styles/Global.module.scss";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Link,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import theme from "../../theme";
import { Box } from "@mui/system";
import { EventAvailable } from "@mui/icons-material";

interface TicketCardComponentProps {
  ticket: Ticket;
  onTicketSelected: (dicounted: boolean, ticket: Ticket) => void;
  onTicketRemoved: (dicounted: boolean, ticket: Ticket) => void;
}

const TicketCardComponent: FC<TicketCardComponentProps> = (props) => {
  const [open, setOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [discounted, setDicsounted] = useState(false);
  const [includingCamping, setIncludingCamping] = useState(false);
  const [amount, setAmount] = useState(0);

  function addTicket() {
    if (!props.ticket.showPopup) {
      setAmount(amount + 1);
      props.onTicketSelected(discounted, props.ticket);
    } else {
      setShowPopup(true);
    }
  }

  function removeTicket() {
    if (!props.ticket.showPopup) {
      const newAmount = amount - 1;
      setAmount(newAmount <= 0 ? 0 : newAmount);
      props.onTicketRemoved(discounted, props.ticket);
    } else {
      setShowPopup(true);
    }
  }

  return (
    <div
      onClick={() => {
        if (props.ticket.available && props.ticket.available > 0)
          setOpen(!open);
      }}
      className={classNames(globalStyles["flex-row"], styles.card, {
        [styles.open]: open,
        [styles["sold-out"]]: !(
          props.ticket.available && props.ticket.available > 0
        ),
      })}
    >
      <Dialog
        open={showPopup}
        onClose={() => {
          setShowPopup(false);
        }}
      >
        <DialogTitle id="alert-dialog-title">{props.ticket.name}</DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            <DialogContentText id="alert-dialog-description">
              {props.ticket.information}
            </DialogContentText>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              setShowPopup(false);
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            Schließen
          </Button>
          <Link href={props.ticket.detailsUrl} target="_blank">
            <Button
              color="primary"
              disableElevation
              variant="contained"
              autoFocus
            >
              Mehr Infos
            </Button>
          </Link>
        </DialogActions>
      </Dialog>

      <div className={styles.overlay}>
        <Typography variant="h4" sx={{ color: "white", margin: 0 }}>
          AUSVERKAUFT
        </Typography>
      </div>

      <img
        style={{ flexShrink: 0 }}
        src={
          props.ticket.imageUrl ??
          "https://images-na.ssl-images-amazon.com/images/I/51N5qVjuKAL._SX309_BO1,204,203,200_.jpg"
        }
        className={styles.book}
      />
      <div className={(classNames(globalStyles["flex-column"]), styles.info)}>
        <div className={styles.title}>{props.ticket.displayName}</div>
        <div className={styles.subtitle}>
          {props.ticket.subheadline || "Online-Ticket"}
        </div>

        <div
          className={classNames(styles.hidden, styles.bottom, styles.summary)}
        >
          {props.ticket.information}
          {open && props.ticket.detailsUrl && (
            <>
              <br />
              <br />
              <Link href={props.ticket.detailsUrl} target="_blank">
                <Button
                  color="primary"
                  disableElevation
                  variant="contained"
                  autoFocus
                >
                  Mehr Infos
                </Button>
              </Link>
            </>
          )}
        </div>
      </div>
      <Stack flexDirection="row" alignItems="flex-end">
        <img
          style={{ flexShrink: 0 }}
          src={
            props.ticket.imageUrl ??
            "https://images-na.ssl-images-amazon.com/images/I/51N5qVjuKAL._SX309_BO1,204,203,200_.jpg"
          }
          className={classNames(styles.book, styles.mobile)}
        />
        <div
          className={(classNames(globalStyles["flex-column"]), styles.group)}
        >
          {props.ticket.available && props.ticket.available > 0 && (
            <>
              <Box
                sx={{
                  color: "white",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }} variant="h5">
                  {props.ticket.price.toLocaleString("de-DE", {
                    style: "currency",
                    currency: "EUR",
                  })}
                </Typography>
              </Box>

              <div
                className={classNames(
                  styles.hidden,
                  styles.bottom,
                  styles.amount
                )}
              >
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    removeTicket();
                  }}
                  style={{
                    backgroundColor: theme.palette.warning.main,
                    color: theme.palette.warning.contrastText,
                  }}
                  className={classNames(
                    styles.btn,
                    styles["fixed-size"],
                    styles.minus
                  )}
                >
                  <span>-</span>
                </button>
                <div className={styles["ticket-counter"]}>
                  <span className={styles.label}>Ausgewählt</span>
                  <span className={styles.amount}>{amount}</span>
                  { props.ticket.available && props.ticket.available <= 50 && ( <span className={styles.available}>
                    Noch&nbsp;
                    {props.ticket.available && props.ticket.available > 0
                      ? props.ticket.available - amount
                      : 0}
                    &nbsp;Verfügbar
                  </span> )}
                </div>

                <button
                  disabled={amount >= props.ticket.available}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    addTicket();
                  }}
                  style={{
                    backgroundColor: theme.palette.success.main,
                    color: theme.palette.success.contrastText,
                  }}
                  className={classNames(
                    styles.btn,
                    styles["fixed-size"],
                    styles.plus
                  )}
                >
                  <span>+</span>
                </button>
              </div>
            </>
          )}
        </div>
      </Stack>
    </div>
  );
};

export default TicketCardComponent;
