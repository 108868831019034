
interface StrapiObject<T> {
    data: {
        id: number;
        attributes: Omit<T, "id">
    }
}


export function StrapiToObject<T>(object: StrapiObject<T>) {

    return { id: object.data.id, ...object.data.attributes } as unknown as T
}