import { Stack, Typography } from "@mui/material";
import { FC, useContext } from "react";
import { AppContext } from "../AppContext";
import { PaymentMethod } from "../interfaces/PaymentMethod";
import LabelAndSublabel from "./LabelAndSublabel";

interface PaymentMethodLabelProps {
  paymentMethodId: string;
}

const PaymentMethodLabel: FC<PaymentMethodLabelProps> = (props) => {
  const { paymentMethods } = useContext(AppContext);

  return (
    <LabelAndSublabel label="Bezahlmethode">
      {paymentMethods.find((method) => method.id === props.paymentMethodId)
        ?.description ?? "Unbekannt"}
    </LabelAndSublabel>
  );
};

export default PaymentMethodLabel;
