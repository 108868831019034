import { Card, Radio, Stack, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC } from "react";
import { MolliePaymentMethod } from "../interfaces/MolliePaymentMethod";

interface PaymentMethodButtonProps {
  method: MolliePaymentMethod;
  selected: boolean;
  onClick: () => void;
}

const PaymentMethodButton: FC<PaymentMethodButtonProps> = (props) => {
  return (
    <Card
      elevation={2}
      sx={{
        padding: 2,
        cursor: "pointer",
      }}
      onClick={() => {
        props.onClick();
      }}
    >
      <Box sx={{ display: "flex", gap: 2 }}>
        <Radio
          checked={props.selected}
          value="a"
          name="radio-buttons"
          inputProps={{ "aria-label": "A" }}
        />
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          style={{ marginBottom: 0 }}
        >
          <img src={props.method.image.size2x} style={{ height: "40px" }} />
          <Typography variant="h6">{props.method.description}</Typography>
        </Stack>
      </Box>
    </Card>
  );
};

export default PaymentMethodButton;
