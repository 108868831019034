import styles from "./CheckoutStepper.module.scss";

import {
  Button,
  Card,
  Grid,
  Paper,
  Stack,
  Step,
  StepLabel,
  StepLabelProps,
  Stepper,
  StepProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Divider,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Tooltip,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { Box } from "@mui/system";
import { Form, Formik, useFormik } from "formik";
import { useState } from "react";
import { FC } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as yup from "yup";
import {
  loadFromLocalStorage,
  storeInLocalStorage,
} from "../../helpers/LocalStorageHelper";
import { Address } from "../../interfaces/Address";
import PaymentMethodButton from "../PaymentMethodButton";

import PayPalLogo from "../../img/paypal.png";
import ÜberweisungLogo from "../../img/überweisung.png";
import CreditCardLogo from "../../img/creditcard.png";
import { Ticket } from "../../interfaces/Ticket.interface";
import { SelectedTicket } from "../../interfaces/SelectedTicket";
import { useEffect } from "react";
import { useContext } from "react";
import StrapiClient from "../../StrapiClient";
import { AppContext } from "../../AppContext";
import PaymentMethodLabel from "../PaymentMethodLabel";
import { PaymentMethod } from "../../interfaces/PaymentMethod";
import { MolliePaymentMethod } from "../../interfaces/MolliePaymentMethod";
import { isMethodDeclaration } from "typescript";
import LabelAndSublabel from "../LabelAndSublabel";
import pixel from "../../facebookPixel";

const ADDRESS_LS_KEY = "2takt_checkout_temp_address";

const steps = [
  "Select campaign settings",
  "Create an ad group",
  "Create an ad",
  "Create an ad",
];

interface CheckoutStepperProps {
  tickets: Ticket[];
}

const CheckoutStepper: FC<CheckoutStepperProps> = (props) => {
  const { user, paymentMethods } = useContext(AppContext);

  const [activeStep, setActiveStep] = useState(0);
  const [acceptedAGB, setAcceptedAGB] = useState(false);
  const [agbError, setAGBError] = useState(false);
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [coupon, setCoupon] = useState<string>("");
  const [intermediateCouponCode, setIntermediateCouponCode] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponCodeError, setCouponCodeError] = useState<string>();

  useEffect(() => {
    setLoadingOrder(false);
  }, []);

  const [paymentMethod, setPaymentMethod] = useState<MolliePaymentMethod>(
    paymentMethods?.[0] ?? null
  );
  const [skipped, setSkipped] = useState(new Set());

  function setNeedsAGBError() {
    setAGBError(true);
    setTimeout(() => setAGBError(false), 6000);
  }

  const queryParams = new Proxy(new URLSearchParams(window.location.search), {
    //@ts-ignore
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const addressValidationSchema = yup.object({
    firstName: yup
      .string()
      .min(2)
      .required("Dieses Feld muss ausgefüllt werden"),
    lastName: yup.string().required("Dieses Feld muss ausgefüllt werden"),
    street: yup.string().required("Dieses Feld muss ausgefüllt werden"),
    city: yup.string().required("Dieses Feld muss ausgefüllt werden"),
    zip: yup.string().required("Dieses Feld muss ausgefüllt werden"),
    email: yup.string().email().required("Dieses Feld muss ausgefüllt werden"),
  });

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    switch (activeStep) {
      case 0:
        break;
      case 1:
        break;
      case 2:
        pixel.track("AddPaymentInfo");
        break;
      case 3:
        break;
    }
  }, [activeStep]);

  const selectedAddress = loadFromLocalStorage<Address>(ADDRESS_LS_KEY, {});

  const reducedTickets = Object.values(
    props.tickets.reduce<{
      [key: number]: { amount: number } & Ticket;
    }>((prev, curr) => {
      const prevValue = prev[curr.id];
      if (prevValue) {
        prevValue.amount += 1;
      } else {
        prev[curr.id] = { ...curr, amount: 1 };
      }
      return prev;
    }, {})
  );

  return (
    <Stack
      gap={2}
      sx={{ width: "100%", padding: 2 }}
      className={styles["stepper-wrapper"]}
    >
      <Stepper
        activeStep={activeStep}
        sx={{ paddingBottom: 2 }}
        orientation={window.innerWidth <= 500 ? "vertical" : "horizontal"}
      >
        <Step>
          <StepLabel>Bezahlinformationen</StepLabel>
        </Step>
        <Step>
          <StepLabel>Bezahlmethode</StepLabel>
        </Step>
        <Step>
          <StepLabel>Für Freunde</StepLabel>
        </Step>
        <Step>
          <StepLabel>Übersicht</StepLabel>
        </Step>
        <Step>
          <StepLabel>Abschluss</StepLabel>
        </Step>
      </Stepper>

      {activeStep === 0 && (
        <Formik
          onSubmit={(values, actions) => {
            handleNext();
          }}
          validationSchema={addressValidationSchema}
          enableReinitialize={true}
          initialValues={loadFromLocalStorage<Address>(ADDRESS_LS_KEY, {})}
        >
          {({
            setFieldValue,
            setFieldTouched,
            values,
            errors,
            touched,
            handleChange,
            submitForm,
          }) => {
            storeInLocalStorage(ADDRESS_LS_KEY, values);

            return (
              <Stack gap={2}>
                <Form>
                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                      <Typography variant="h4">
                        Gib deine Bezahlinformationen ein
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        name="firstName"
                        sx={{ width: "100%" }}
                        label="Vorname"
                        value={values.firstName}
                        onChange={handleChange}
                        error={Boolean(errors.firstName)}
                        helperText={errors.firstName}
                      ></TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        name="lastName"
                        sx={{ width: "100%" }}
                        label="Nachname"
                        value={values.lastName}
                        onChange={handleChange}
                        error={Boolean(errors.lastName)}
                        helperText={errors.lastName}
                      ></TextField>
                    </Grid>
                    <Grid item md={9} xs={12}>
                      <TextField
                        name="street"
                        sx={{ width: "100%" }}
                        label="Straße"
                        value={values.street}
                        onChange={handleChange}
                        error={Boolean(errors.street)}
                        helperText={errors.street}
                      ></TextField>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <TextField
                        name="zip"
                        sx={{ width: "100%" }}
                        label="Postleitzahl"
                        value={values.zip}
                        onChange={handleChange}
                        error={Boolean(errors.zip)}
                        helperText={errors.zip}
                      ></TextField>
                    </Grid>
                    <Grid item md={8} xs={12}>
                      <TextField
                        name="city"
                        sx={{ width: "100%" }}
                        label="Stadt"
                        value={values.city}
                        onChange={handleChange}
                        error={Boolean(errors.city)}
                        helperText={errors.city}
                      ></TextField>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextField
                        name="email"
                        sx={{ width: "100%" }}
                        label="Email"
                        value={values.email}
                        onChange={handleChange}
                        error={Boolean(errors.email)}
                        helperText={
                          errors.email ||
                          "An diese Email-Adresse erhälst du deine Tickets"
                        }
                      ></TextField>
                    </Grid>
                  </Grid>
                </Form>

                <Stack gap={2} alignItems="flex-end">
                  <img
                    style={{ maxWidth: "300px" }}
                    src="https://2takt-festival.de/wp-content/uploads/2022/06/mollie/mollie_secured.png"
                  />

                  <Stack gap={2} direction="row" justifyContent="space-between">
                    <div style={{ flexGrow: 2 }}></div>
                    <Button
                      disableElevation
                      variant="contained"
                      onClick={() => {
                        submitForm();
                      }}
                    >
                      Weiter
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            );
          }}
        </Formik>
      )}

      {activeStep === 1 && (
        <Stack gap={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                {paymentMethods.map((method) => (
                  <PaymentMethodButton
                    method={method}
                    selected={paymentMethod?.id === method.id}
                    onClick={() => setPaymentMethod(method)}
                  />
                ))}
              </Box>
            </Grid>
          </Grid>
          <Stack gap={2} alignItems="flex-end">
            <img
              style={{ maxWidth: "300px" }}
              src="https://2takt-festival.de/wp-content/uploads/2022/06/mollie/mollie_secured.png"
            />
            <Stack gap={2} direction="row" justifyContent="space-between">
              <Button
                disableElevation
                color="inherit"
                onClick={handleBack}
                sx={{ mr: 1 }}
                variant="contained"
              >
                Zurück
              </Button>
              <div style={{ flexGrow: 2 }}></div>
              <Button
                disableElevation
                variant="contained"
                onClick={() => {
                  handleNext();
                }}
              >
                Weiter
              </Button>
            </Stack>
          </Stack>
        </Stack>
      )}

      {activeStep === 2 && (
        <Stack spacing={0}>
          <Paper sx={{ padding: 2, maxWidth: "90vw" }} elevation={2}>
            <Grid container spacing={1.5}>
              <Grid item md={12} xs={12}>
                <Typography variant="h6">Code von Freunden</Typography>
                <Typography>
                  <b>Hast du von Freunden einen Code zugesendet bekommen?</b>
                  <br />
                  Dann kannst du Ihn hier einlösen. Löst du den Code ein, erhält
                  die Person, von der der Code stammt zwei Token auf dem
                  Festival*
                  <br />
                  <br />
                  <b>Hast du noch keinen Code? 🤔</b>
                  <br />
                  Für eine abgeschlossene Bestellung erhälst du einen Code in
                  der Bestätigungsemail den du dann an Freunde weiter geben
                  kannst.
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  value={
                    coupon?.substring(0, 4) +
                    ((coupon ?? "").substring(4, 8).length > 0 &&
                    (coupon ?? "").charAt(4) !== "-"
                      ? "-"
                      : "") +
                    (coupon ?? "").substring(4, 9)
                  }
                  onChange={(e) => {
                    const value = e.target.value ?? "";

                    if (!coupon || (coupon.length < 10 && value.length < 10)) {
                      setCoupon(value);
                    }
                  }}
                  fullWidth
                  variant="filled"
                  label="Code hier eingeben"
                  placeholder="XXXX-XXXX"
                ></TextField>
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography variant="caption">
                  *2 Token pro bestelltem Ticket. Maximal 20. Mindestens 2
                  eingelöste Tickets. Alles weiter findest du im Abschnitt 12
                  der AGB.
                </Typography>
              </Grid>
            </Grid>
            <Stack gap={2} alignItems="flex-end" sx={{ mt: 3 }}>
              <img
                style={{ maxWidth: "300px" }}
                src="https://2takt-festival.de/wp-content/uploads/2022/06/mollie/mollie_secured.png"
              />
              <Stack gap={2} direction="row" justifyContent="space-between">
                <Button
                  disableElevation
                  color="inherit"
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                  variant="contained"
                >
                  Zurück
                </Button>
                <div style={{ flexGrow: 2 }}></div>
                <Button
                  disableElevation
                  variant="contained"
                  onClick={() => {
                    handleNext();
                  }}
                >
                  Weiter
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </Stack>
      )}

      {activeStep === 3 && (
        <Stack spacing={0}>
          <Paper sx={{ padding: 2, maxWidth: "90vw" }} elevation={2}>
            <Grid container spacing={1.5}>
              <Grid item md={12} xs={12}>
                <Typography variant="h6">Adressdaten</Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <Stack>
                  <Typography variant="caption">Vorname</Typography>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {selectedAddress.firstName}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item md={6} xs={12}>
                <Stack>
                  <Typography variant="caption">Nachname</Typography>
                  <Typography sx={{ fontWeight: "bold" }} variant="body1">
                    {selectedAddress.lastName}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item md={6} xs={12}>
                <Stack>
                  <Typography variant="caption">Straße</Typography>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {selectedAddress.street}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item md={6} xs={12}>
                <Stack>
                  <Typography variant="caption">Postleitzahl</Typography>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {selectedAddress.zip}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item md={8} xs={12}>
                <Stack>
                  <Typography variant="caption">Stadt</Typography>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {selectedAddress.city}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item md={12} xs={12}>
                <Stack>
                  <Typography variant="caption">Email</Typography>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {selectedAddress.email}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>

            <Divider sx={{ paddingTop: 2, marginBottom: 2 }} />

            <Grid container spacing={1.5}>
              <Grid item md={6} xs={12}>
                <PaymentMethodLabel paymentMethodId={paymentMethod?.id} />
              </Grid>
              <Grid item md={6} xs={12}>
                <LabelAndSublabel label="Eingelöster Code">
                  {coupon && coupon.length === 9
                    ? coupon
                    : "Kein gültiger Code angegeben"}
                </LabelAndSublabel>
              </Grid>
            </Grid>

            <Divider sx={{ paddingTop: 2 }} />

            <Typography sx={{ paddingTop: 2 }} variant="h6">
              Tickets
            </Typography>
            <TableContainer>
              <Table aria-label="Summary Table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="right">Anzahl</TableCell>
                    <TableCell align="right">Preis</TableCell>
                    <TableCell align="right">Enthaltene MwSt. (7%)</TableCell>
                    <TableCell align="right">Gesamtpreis</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reducedTickets
                    .sort((a, b) => a.displayName.localeCompare(b.displayName))
                    .map((row) => (
                      <TableRow
                        key={row.displayName}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.displayName}
                        </TableCell>
                        <TableCell align="right">{row.amount}</TableCell>
                        <TableCell align="right">
                          {row.price.toLocaleString("de-DE", {
                            style: "currency",
                            currency: "EUR",
                          })}
                        </TableCell>
                        <TableCell align="right">
                          {row.mwSt.toLocaleString("de-DE", {
                            style: "currency",
                            currency: "EUR",
                          })}
                        </TableCell>
                        <TableCell align="right">
                          {(row.price * row.amount).toLocaleString("de-DE", {
                            style: "currency",
                            currency: "EUR",
                          })}
                        </TableCell>
                      </TableRow>
                    ))}

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Gesamt
                      </Typography>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {props.tickets
                        .reduce((prev, curr) => prev + curr.mwSt, 0)
                        .toLocaleString("de-DE", {
                          style: "currency",
                          currency: "EUR",
                        })}
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        {props.tickets
                          .reduce((prev, curr) => prev + curr.price, 0)
                          .toLocaleString("de-DE", {
                            style: "currency",
                            currency: "EUR",
                          })}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  {/** @ts-ignore */}
                  {queryParams?.enableCouponCode && (
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        colSpan={5}
                        sx={{ p: 0 }}
                      >
                        <Accordion elevation={0}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                          >
                            <Typography variant="caption">
                              Ich habe einen Coupon-Code
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack gap={1}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                gap={1}
                              >
                                <TextField
                                  fullWidth
                                  size="small"
                                  placeholder="Coupon Code"
                                  onChange={(e) =>
                                    setCouponCode(e.target.value)
                                  }
                                />
                                {/* <Button disableElevation variant="contained">
                                Anwenden
                              </Button> */}
                              </Stack>
                              {/* {couponCodeError && (
                              <Typography color="error">
                                {couponCodeError}
                              </Typography>
                            )} */}
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Box
            sx={{ display: "flex", flexDirection: "row", pt: 2 }}
            className={styles.checkout}
          >
            <div style={{ flexGrow: 2 }}></div>

            {activeStep === steps.length - 1 && (
              <Stack flexDirection="column" alignItems="flex-end" gap={2}>
                <img
                  style={{ maxWidth: "300px" }}
                  src="https://2takt-festival.de/wp-content/uploads/2022/06/mollie/mollie_secured.png"
                />

                <Stack gap={1} className={styles["inner-stack"]}>
                  <Stack flexDirection="row">
                    <Button
                      disableElevation
                      color="inherit"
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                      variant="contained"
                    >
                      Zurück
                    </Button>
                    <Button
                      disableElevation
                      variant="contained"
                      onClick={() => {
                        setLoadingOrder(true);
                        StrapiClient.createOrder(
                          props.tickets.map((ticket) => ticket.id),
                          paymentMethod?.id,
                          selectedAddress,
                          user?.accessToken,
                          coupon ?? undefined,
                          couponCode
                        ).then(
                          // @ts-ignore
                          (result) => (window.location = result.checkoutUrl)
                        );
                      }}
                    >
                      {`Jetzt für ${props.tickets
                        .reduce((prev, curr) => prev + curr.price, 0)
                        .toLocaleString("de-DE", {
                          style: "currency",
                          currency: "EUR",
                        })} kaufen`}
                    </Button>
                  </Stack>

                  <Typography variant="caption" className={styles.text}>
                    Mit dem Klick auf kaufen bestätige ich, dass ich die{" "}
                    <a
                      href="https://2takt-festival.de/?page_id=44"
                      target="_blank"
                      rel="noreferrer"
                    >
                      AGB
                    </a>{" "}
                    und{" "}
                    <a
                      href="https://2takt-festival.de/?page_id=46"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Datenschutzerklärung
                    </a>{" "}
                    gelesen habe und Ihnen zustimme.
                  </Typography>
                </Stack>
              </Stack>
            )}
          </Box>
        </Stack>
      )}

      <Dialog open={loadingOrder} onClose={() => {}}>
        <DialogTitle id="alert-dialog-title">
          Bestellung wird bearbeitet
        </DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            <DialogContentText id="alert-dialog-description">
              Deine Bestellung wird bearbeitet. Du wirst in ein paar Sekunden
              zur Bezahlung weitergeleitet.
            </DialogContentText>
            <CircularProgress></CircularProgress>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLoadingOrder(false)}>Abbrechen</Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default CheckoutStepper;
