import { createContext } from "react";
import { MolliePaymentMethod } from "./interfaces/MolliePaymentMethod";
import { TempUser, TicketNotice } from "./StrapiClient";

export interface AppContextInterface {
    orderId: number | null,
    user: TempUser | null;
    setUser: React.Dispatch<React.SetStateAction<TempUser | null>>;
    ticketNotice?: TicketNotice,
    paymentMethods: MolliePaymentMethod[]
}

export const AppContext = createContext<AppContextInterface>({} as AppContextInterface);