import { Stack, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import { FC } from "react";
import { JsxElement } from "typescript";

interface LabelAndSublabelProps {
  label: string;
  children: JSX.Element | string | number;
}

const LabelAndSublabel: FC<LabelAndSublabelProps> = (props) => {
  return (
    <Stack>
      <Typography variant="caption">{props.label}</Typography>
      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
        {props.children}
      </Typography>
    </Stack>
  );
};

export default LabelAndSublabel;
