import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { PaymentMethod } from "../interfaces/PaymentMethod";
import { PaymentStatus } from "../interfaces/PaymentStatus";
import LabelAndSublabel from "./LabelAndSublabel";

interface PaymentStautsLabelProps {
  paymentStatus: PaymentStatus;
}

const PaymentStautsLabel: FC<PaymentStautsLabelProps> = (props) => {
  return (
    <LabelAndSublabel label="Bezahlstatus">
      {props.paymentStatus === PaymentStatus.Authorized
        ? "Authorisiert"
        : props.paymentStatus === PaymentStatus.Paid
        ? "Bezahlt"
        : props.paymentStatus === PaymentStatus.Open
        ? "Offen"
        : props.paymentStatus === PaymentStatus.Pending
        ? "Ausstehend"
        : props.paymentStatus === PaymentStatus.Expired
        ? "Ausgelaufen"
        : props.paymentStatus === PaymentStatus.Failed
        ? "Fehlgeschlagen"
        : props.paymentStatus === PaymentStatus.Canceled
        ? "Abgebrochen"
        : "Unbekannt"}
    </LabelAndSublabel>
  );
};

export default PaymentStautsLabel;
